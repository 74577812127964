//在这个文件中根路由 / 必须定义，children可自定义
const _import = require("../_import_" + process.env.NODE_ENV);

const routes = [
  {
    path: "/",
    name: "layout",
    component: _import("layout"),
    redirect: "/home",
    children: [
      {
        path: "/graphicAndTextual",
        name: "GraphicAndTextual",
        component: _import("publicityMaterial/graphicAndTextual/index"),
        meta: {
          bread: ["学习平台", "政策法规"]
        }
      },
      {
        path: "/videos",
        name: "Videos",
        component: _import("publicityMaterial/videos/index"),
        meta: {
          bread: ["宣传资料", "视频资料"]
        }
      }
    ],
  },
];
export default routes;