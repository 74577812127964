//在这个文件中根路由 / 必须定义，children可自定义
const _import = require("../_import_" + process.env.NODE_ENV);

const routes = [
  {
    path: "/",
    name: "layout",
    component: _import("layout"),
    redirect: "/home",
    children: [
      {
        path: "/infoopen",
        name: "Infoopen",
        component: _import("Infoopen/index"),
        meta: {
          bread: ["信息公开"]
        }
      },
    ],
  },
];
export default routes;