//在这个文件中根路由 / 必须定义，children可自定义
const _import = require("../_import_" + process.env.NODE_ENV);

const routes = [
  {
    path: "/",
    name: "layout",
    component: _import("layout"),
    redirect: "/home",
    children: [
      {
        path: "/humanitarian",
        name: "Humanitarian",
        component: _import("Humanitarian/humanitarian"),
        meta: {
          bread: ["项目管理","项目简介"],
        },
      },
      {
        path: "/proActivity",
        name: "ProActivity",
        component: _import("Humanitarian/proActivity"),
        meta: {
          bread: ["项目管理","项目动态"],
        },
      },
      {
        path: "/proIntroduce",
        name: "ProIntroduce",
        component: _import("Humanitarian/proIntroduce"),
        meta: {
          bread: ["项目管理","项目捐赠"],
        },
      },
    ],
  },
];
export default routes;
