//在这个文件中根路由 / 必须定义，children可自定义
const _import = require("../_import_" + process.env.NODE_ENV);

const routes = [
  {
    path: "/",
    name: "layout",
    component: _import("layout"),
    redirect: "/emergency",
    children: [
      {
        path: "/emergency",
        name: "emergency",
        component: _import("Emergency/index"),
        meta: {
          bread: ["应急救援", "灾害管理"],
        },
      },
      {
        path: "/team",
        name: "team",
        component: _import("Emergency/team"),
        meta: {
          bread: ["应急救援", "救援队伍"],
        },
      },
      {
        path: "/system",
        name: "system",
        component: _import("Emergency/system"),
        meta: {
          bread: ["应急救援", "备灾救灾"],
        },
      }, 
      {
        path: "/rebuild",
        name: "rebuild",
        component: _import("Emergency/rebuild"),
        meta: {
          bread: ["应急救援", "灾后重建"],
        },
      },
    ],
  },
];
export default routes;
