const _import = require("../_import_" + process.env.NODE_ENV);

const routes = [
  {
    path: "/",
    name: "layout",
    component: _import("layout"),
    redirect: "/home",
    children: [
      {
        path: "/humanhelp",
        name: "Humanhelp",
        component: _import("Humanhelp/index"),
        meta: {
          bread: ["人道救助","救助行动"]
        }
      },
      {
        path: "/lovetoall",
        name: "Lovetoall",
        component: _import("Humanhelp/Lovetoall/index"),
        meta: {
          bread: ["人道救助","博爱送万家"]
        }
      },
      {
        path: "/helppublic",
        name: "helppublic",
        component: _import("Humanhelp/Helppublic/index"),
        meta: {
          bread: ["人道救助","救助公示"]
        }
      },
      {
        path: "/helpguide",
        name: "Helpguide",
        component: _import("Humanhelp/Helpguide/index"),
        meta: {
          bread: ["人道救助","救助指南"]
        }
      },
      {
        path: "/helptrends",
        name: "helptrends",
        component: _import("Humanhelp/Helptrends/index"),
        meta: {
          bread: ["人道救助","工作动态"]
        }
      },
      {
        path: "/angelfund",
        name: "Angelfund",
        component: _import("Humanhelp/Angelfund/index"),
        meta: {
          bread: ["人道救助","彩票金白血病救助"]
        }
      },
      {
        path: "/angelsunfund",
        name: "Angelsunfund",
        component: _import("Humanhelp/Angelsunfund/index"),
        meta: {
          bread: ["人道救助","彩票金先心病救助"]
        }
      },{
        path: "/sxhelppublic",
        name: "Sxhelppublic",
        component: _import("Humanhelp/sxhelppublic/index"),
        meta: {
          bread: ["人道救助",'"三献"救助']
        }
      },{
        path: "/hongkong",
        name: "hongkong",
        component: _import("Humanhelp/hongkong/index"),
        meta: {
          bread: ["人道救助",'香港新康复项目']
        }
      }
    ],
  },
];
export default routes;