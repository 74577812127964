//在这个文件中根路由 / 必须定义，children可自定义
const _import = require("../_import_" + process.env.NODE_ENV);

const routes = [
  {
    path: "/",
    name: "layout",
    component: _import("layout"),
    redirect: "/rescueIntroduce",
    children: [
      {
        path: "/rescue",
        name: "rescue",
        component: _import("Rescue/index"),
        meta: {
          bread: ["应急救护", "应急救护小知识"],
        },
      },
      {
        path: "/class",
        name: "class",
        component: _import("Rescue/class"),
        meta: {
          bread: ["应急救护", "应急救护课程"],
        },
      },
      {
        path: "/classDetail",
        name: "classDetail",
        component: _import("Rescue/classDetail"),
        meta: {
          bread: ["应急救护", "应急救护课程"],
        },
      },
      {
        path: "/server",
        name: "server",
        component: _import("Rescue/server"),
        meta: {
          bread: ["应急救护", "应急救护简介 "],
        },
      },
      {
        path: "/rescueIntroduce",
        name: "rescueIntroduce",
        component: _import("Rescue/introduce"),
        meta: {
          bread: ["应急救护", "应急救护简介 "],
        }, 
      },
    ],
  },
];
export default routes;
