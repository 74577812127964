//在这个文件中根路由 / 必须定义，children可自定义
const _import = require("../_import_" + process.env.NODE_ENV);

const routes = [
  {
    path: "/",
    name: "layout",
    component: _import("layout"),
    redirect: "/home",
    children: [
      {
        path: "/bodydonate",
        name: "Bodydonate",
        component: _import("Bodydonate/index"),
        meta: {
          bread: ["器官/遗体/角膜捐献"],
        }
      },
	  {
	    path: "/gzdt",
	    name: "Gzdt",
	    component: _import("Bodydonate/gzdt"),
	    meta: {
	      bread: ["器官/遗体/角膜捐献"," 工作动态"]
	    }
	  },
	  {
	    path: "/scsjbqkjj",
	    name: "Scsjbqkjj",
	    component: _import("Bodydonate/scsjbqkjj"),
	    meta: {
	      bread: ["器官/遗体/角膜捐献","四川省基本情况简介"]
	    }
	  },
	  {
	    path: "/mhjl",
	    name: "Mhjl",
	    component: _import("Bodydonate/mhjl"),
	    meta: {
	      bread: ["器官/遗体/角膜捐献"," 缅怀纪念"]
	    }
	  },
	  {
	    path: "/kpzs",
	    name: "Kpzs",
	    component: _import("Bodydonate/kpzs"),
	    meta: {
	      bread: ["器官/遗体/角膜捐献","科普知识"]
	    }
	  },
	  {
	    path: "/bodyRegister",
	    name: "BodyRegister",
	    component: _import("Bodydonate/bodyRegister"),
	    meta: {
	      bread: ["器官/遗体/角膜捐献","我要登记"]
	    }
	  },
      {
        path: "/brance",
        name: "Brance",
        component: _import("Bodydonate/brance/index"),
        meta: {
          bread: ["器官/遗体/角膜捐献"," 缅怀纪念"]
        }
      },
       
    ],
  },
];
export default routes;