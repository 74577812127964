//示例，根据项目修改或重新创建
import https from "../https";
import config from "@/config";

export default {
    //获取工作动态列表
   getstemcell(params) {
    return https.get(`${config.baseUrl}/api/info/list`, params);
  },
   // 干细胞采集基础信息
   getStatistics(params) {
    return https.get(`${config.baseUrl}/api/info/getByNode`, params);
   }
};