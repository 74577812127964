//示例，根据项目修改或重新创建
import https from "../https";
import config from "@/config";

export default {
  homeBanner(params) {
    return https.get(`${config.baseUrl}/api/ad/list`, params);
  },
  selfDynamicList(params) {
    return https.get(`${config.baseUrl}/api/info/list`, params);
  },
  selfDynamicDetail(params) {
    return https.get(`${config.baseUrl}/api/info/getById`, params);
  },
  friendlink(params) {
    return https.get(`${config.baseUrl}/api/friendlink/list`, params);
  },
  trainNum(params) {
    return https.get(`${config.baseUrl}/api/info/getByNode`, params);
  },
  donate(params) {
    return https.post(
      `/donateApi/donate/portal/saveOnlineDonatePc.json`,
      params
    );
  },
  donatePC(params) {
    return https.post(
      `/donateApi/donate/portal/saveOnlineDonate.json`,
      params
    );
  },
  donateThing(params) {
    return https.post(
      `/donateApi/donate/portal/saveOnlineGoodPc.json`,
      params
    );
  },
  dictionaryId(params) {
    return https.get(
      `/donateApi/wsbpsys/portal/selectDictionaryData.json`,
      params
    );
  },
  donateMsg(params) {
    return https.get(
      `/donateApi/donate/portal/getOnlineDonateList.json`,
      params
    );
  },
  addViews(params) {
    return https.get(`${config.baseUrl}/api/info/addViews`, params);
  },
  saveMsg(params) {
    return https.post(`${config.baseUrl}/api/guestbook/save`, params);
  },
  msgList(params) {
    return https.get(`${config.baseUrl}/api/guestbook/list`, params);
  },
  msgType(params) {
    return https.get(
      `${config.baseUrl}/api/guestbook/guestbookTypeList`,
      params
    );
  },
  otherDonate(params) {
    return https.get(`${config.baseUrl}/api/fulltext/list`, params);
  },
  yjData(params) {
    return https.get(`${config.baseUrl}/api/info/getByNode`, params);
  },
  keyWords(params) {
    return https.get(`${config.baseUrl}/api/fulltext/list`, params);
  }
};
