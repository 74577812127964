
const _import = require("../_import_" + process.env.NODE_ENV);

const routes = [
  {
    path: "/",
    name: "layout",
    component: _import("layout"),
    redirect: "/home",
    children: [
      {
        path: "/stemcells",
        name: "Stemcells",
        component: _import("Stemcells/index"),
        meta: {
          bread: ["造血干细胞捐献","工作动态"]
        }
      },
      {
        path: "/sichuan",
        name: "Sichuan",
        component: _import("Stemcells/sichuan"),
        meta: {
          bread: ["造血干细胞捐献","四川省基本情况简介"]
        }
      },
      {
        path: "/elegant",
        name: "Elegant",
        component: _import("Stemcells/elegant"),
        meta: {
          bread: ["造血干细胞捐献","造干志愿者风采"]
        }
      },
      {
        path: "/knowledge",
        name: "Knowledge",
        component: _import("Stemcells/knowledge"),
        meta: {
          bread: ["造血干细胞捐献","科普知识"]
        }
      },
	  {
	    path: "/wcxx",
	    name: "Wcxx",
	    component: _import("Stemcells/wcxx"),
	    meta: {
	      bread: ["无偿献血"]
	    }
	  },
	  {
	    path: "/brance2",
	    name: "Brance2",
	    component: _import("Stemcells/brance2"),
	    meta: {
	      bread: ["造血干细胞捐献","无偿献血"]
	    }
	  },
      {
        path: "/stemcellsRegister",
        name: "StemcellsRegister",
        component: _import("Stemcells/stemcellsRegister"),
        meta: {
          bread: ["造血干细胞捐献","我要登记"]
        }
      }
    ],
  },
];
export default routes;
